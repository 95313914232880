<template>
<div>
  <v-card max-width="370" class="mx-auto mt-5" flat>

    <Errors v-bind:errors="errors"/>

    <v-card-title>
      {{teacher.firstname}} 
      {{teacher.lastname}}
    </v-card-title>
    <v-card-subtitle>
        Lehrer:in
    </v-card-subtitle>
    <v-card-text>
      <p v-if="teacher.phone">
        Telefon: <a v-bind:href="'phone:' + teacher.phone">{{ teacher.phone }}</a>
      </p>
      <p v-if="teacher.showEmail">E-Mail: 
        <a v-bind:href="'mailto:' + teacher.email">{{ teacher.email }}</a>
      </p>
      <p>
        {{teacher.info}}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-container>
          <v-row v-if="shownToOwner || isAdmin" class="my-2">
              <v-btn
                color="primary"
                width="100%"
                depressed
                @click="$router.push('/editteacher/' + teacherId)">
                Infos bearbeiten
              </v-btn>
          </v-row>
          <v-row v-if="shownToOwner || isAdmin" class="my-2">
              <v-btn
              width="100%"
              color="primary"
              depressed
              @click="$router.push('/changeemail')"
              >
                E-Mail Ändern
              </v-btn>
          </v-row>

          <v-row class="my-2 mt-8">
            <v-btn
              color="secondary"
              width="100%"
              depressed
              @click="$router.push('/eventsof/teacher/' + teacher.id)">
              Stunden anzeigen
            </v-btn>
          </v-row>
        </v-container>
    </v-card-actions>

    <!-- Last Events of this teacher -->
    <v-card-text v-if="events.length">
      <div 
        v-for="event in events" 
        v-bind:key="event.id"
        >
          <EventInfos v-bind:event="event"/>
      </div>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex';
import { auth, teachersCollection, eventsCollection } from '@/firebase';
import Errors from '@/components/Errors.vue';
import EventInfos from '@/components/EventInfos.vue';

export default {
  name: 'InfoTeacher',
  props: ['teacherId'],

  components: {
    Errors,
    EventInfos,
  },

  data() {
    return {
      errors: [],
      teacher: {showEmail: false},
      shownToOwner: false,
      events: [],
    }
  },

  computed: {
    ...mapState(['isAdmin']),
  },

  async created() {
    this.getTeacher();
  },

  methods: {

    async getTeacher(){

      const teacherDoc = await teachersCollection.doc(this.teacherId).get();
      if (teacherDoc.exists) {
        this.teacher = teacherDoc.data();
        this.teacher.id = teacherDoc.id;

        if (this.teacherId == auth.currentUser.uid){
          this.shownToOwner = true;
        }

      } else { 
        this.errors.push({
          text: 'Die Lehrerin / der Lehrer konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        throw "Could not find this doument in firestore";
      }
    },


  }
}
</script>